import React, { useEffect, useState } from 'react';

const Lead = () => {
  const backendURL = process.env.REACT_APP_BackendURL;
  
  const [messages, setMessages] = useState([]);
  const [subscribers, setSubscribers] = useState([]);
  const [searchTerm, setSearchTerm] = useState(''); // State for search term

  const fetchSubscribers = async () => {
    try {
      const response = await fetch(`${backendURL}/api/subscribe`);
      const data = await response.json();
      setSubscribers(data);
      console.log("Subscribers:", data);
    } catch (error) {
      console.error("Error fetching subscribers:", error);
    }
  };

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await fetch(`${backendURL}/api/contact/messages`);
        const data = await response.json();
        setMessages(data);
        console.log('Messages:', data);
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };
    fetchSubscribers();
    fetchMessages();
  }, []);

  const filteredMessages = messages.filter((message) =>
    message.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
    message.phone.includes(searchTerm)
  );
  
  return (
    <div>
      <div className="text-center">
        <div className='p-4 pt-5'>
          <div className="card shadow">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h5 className="mb-0">Leads</h5>
            </div>
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-4">
                  <input 
                    className="form-control me-2" 
                    type="text" 
                    aria-label="Search"
                    placeholder="Search by email, or phone..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)} // Update search term
                  />
                </div>
              </div>

              <table className="table">
                <thead className="table-dark">
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Message</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredMessages.map((message, index) => (
                    <tr key={message._id}>
                      <td>{index + 1}</td>
                      <td>{message.firstName} {message.lastName}</td>
                      <td>{message.email}</td>
                      <td>{message.phone}</td>
                      <td>{message.message}</td>
                      <td>{new Date(message.createdAt).toLocaleDateString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lead;
