import React, { useState } from "react";

const ContactForm = () => {
  const [email, setEmail] = useState("");
  const backendURL = process.env.REACT_APP_BackendURL; 


  const handleSubmit = async (e) => {
    e.preventDefault();

  
    try {
      const response = await fetch(`${backendURL}/api/subscribe`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        console.log("Email sent successfully!");
        setEmail("");
      } else {
        console.log("Failed to send email.");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      console.log("Error occurred. Try again later.");
    }
  };

  return (
    <div>
      <h2>Get in Touch</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <input
            type="email"
            placeholder="Your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <button type="submit">Send</button>
      </form>
    </div>
  );
};

export default ContactForm;
