import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import Sidebar from '../components/SideBar'; // Adjust the path as needed
import TopNav from '../components/TopNav'; // Adjust the path as needed
import './Bloglist.css';

const Bloglist = () => {
  const [blogs, setBlogs] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [limit] = useState(4);
  const [searchQuery, setSearchQuery] = useState('');
  const [language, setLanguage] = useState('');
  const backendURL = process.env.REACT_APP_BackendURL; 


  const fetchBlogs = useCallback(async () => {
    try {
      const response = await fetch(
        `${backendURL}/api/blogs/blogs?page=${page}&limit=${limit}&search=${searchQuery}&language=${language}`
      );
      if (response.ok) {
        const data = await response.json();
        setBlogs(data.blogs);
        setTotalPages(data.totalPages);
      } else {
        console.error('Failed to fetch blogs');
      }
    } catch (error) {
      console.error('Error fetching blogs:', error);
    }
  }, [page, limit, searchQuery, language]);

  useEffect(() => {
    fetchBlogs();
  }, [fetchBlogs]);

  return (
  

      <div className="content-container">
        {/* Sidebar */}
        <div className="bloglist-container">
      {/* Top Navigation */}
      {/* <TopNav /> */}

        <div className="main-content">
          {/* Filter Buttons */}
          <div className="filter-buttons">
            <button onClick={() => setLanguage('')}>All</button>
            <button onClick={() => setLanguage('English')}>English</button>
            <button onClick={() => setLanguage('Arabic')}>Arabic</button>
          </div>

          {/* Search Bar */}
          <div className="search-bar-container">
            <img src="/images/search.png" alt="search-icon" />
            <input
              type="text"
              placeholder="Search something..."
              className="search-input"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          {/* Blog Grid */}
          <div className="blog-grid">
            {blogs.length > 0 ? (
              blogs.map((blog) => (
                <Link
                  to={`/blogs/${blog._id}`}
                  key={blog._id}
                  className="blog-link"
                >
                  <div
                    className={`blog-card ${
                      blog.language === 'Arabic' ? 'rtl' : ''
                    }`}
                  >
                    <img
                      src={`${blog.headerImage}`}
                      alt={blog.title}
                      className="blog-image"
                    />
                    <div className="blog-name">{parse(blog.title)}</div>
                    <p className="blog-detail">{blog.summary}</p>
                    <button className="learn-more-button">Learn More</button>
                  </div>
                </Link>
              ))
            ) : (
              <p>No blog posts available.</p>
            )}
          </div>

          {/* Pagination */}
          {!searchQuery && (
            <div className="pagination">
              <button
                onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                disabled={page === 1}
              >
                Previous
              </button>
              <span>
                Page {page} of {totalPages}
              </span>
              <button
                onClick={() =>
                  setPage((prev) => (prev < totalPages ? prev + 1 : prev))
                }
                disabled={page === totalPages}
              >
                Next
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Bloglist;
