import React from 'react';

const LinkModal = ({ visible, onClose, onSubmit }) => {
  const [url, setUrl] = React.useState('');
  const [isNofollow, setIsNofollow] = React.useState(false);

  const handleSubmit = () => {
    onSubmit(url, isNofollow);
    onClose(); // Close modal after submission
  };

  if (!visible) return null;

  return (
    <div style={modalStyle}>
      <h2>Add Link</h2>
      <input
        type="text"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        placeholder="Enter the URL"
      />
      <div>
        <label>
          <input
            type="checkbox"
            checked={isNofollow}
            onChange={() => setIsNofollow(!isNofollow)}
          />
          Nofollow
        </label>
      </div>
      <button onClick={handleSubmit}>Submit</button>
      <button onClick={onClose}>Cancel</button>
    </div>
  );
};

// Simple styles for the modal
const modalStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '20px',
  background: '#fff',
  border: '1px solid #ccc',
  zIndex: 1000,
};

export default LinkModal;
