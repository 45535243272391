import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Category = () => {
    const [categories, setCategories] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [limit] = useState(4);
    const [newCategory, setNewCategory] = useState('');
    const [selectedCategory, setSelectedCategory] = useState(null); 
    const [updatedCategoryName, setUpdatedCategoryName] = useState(''); 
    const [categoryToDelete, setCategoryToDelete] = useState(null); 
    const backendURL = process.env.REACT_APP_BackendURL;

    
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch(`${backendURL}/api/categories?page=${page}&limit=${limit}`);
                const data = await response.json();
                if (response.ok) {
                    setCategories(data.categories);
                    setTotalPages(data.totalPages);
                } else {
                    console.error('Error fetching categories:', data.message);
                    toast.error('Error fetching categories');
                }
            } catch (error) {
                console.error('Error fetching categories:', error);
                toast.error('Error fetching categories');
            }
        };
        fetchCategories();
    }, [page, limit]);

    
    const handleAddCategory = async () => {
        if (!newCategory) {
            toast.warning('Please enter a category name');
            return;
        }

        try {
            const response = await fetch(`${backendURL}/api/categories`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name: newCategory }),
            });

            const data = await response.json();
            if (response.ok) {
                setCategories([data.category, ...categories]); 
                setNewCategory(''); 
                toast.success('Category added successfully');
            } else {
                console.error('Error adding category:', data.message);
                toast.error(data.message);
            }
        } catch (error) {
            console.error('Error adding category:', error);
            toast.error('Failed to add category');
        }
    };

   
    const handlePrevPage = () => {
        setPage(prevPage => Math.max(prevPage - 1, 1)); 
    };

    const handleNextPage = () => {
        setPage(prevPage => (prevPage < totalPages ? prevPage + 1 : prevPage)); 
    };

   
    const handleUpdate = (categoryId, categoryName) => {
        setSelectedCategory(categoryId); 
        setUpdatedCategoryName(categoryName); 
        const updateModal = new window.bootstrap.Modal(document.getElementById('updateCategoryModal'));
        updateModal.show();
    };

    
    const handleCategoryUpdate = async () => {
        if (!updatedCategoryName) {
            toast.warning('Please enter a category name');
            return;
        }

        try {
            const response = await fetch(`${backendURL}/api/categories/${selectedCategory}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name: updatedCategoryName }),
            });

            const data = await response.json();
            if (response.ok) {
                
                setCategories(categories.map(category => 
                    category._id === selectedCategory ? { ...category, name: updatedCategoryName } : category
                ));
                toast.success('Category updated successfully');
                
                const updateModal = window.bootstrap.Modal.getInstance(document.getElementById('updateCategoryModal'));
                updateModal.hide();
            } else {
                toast.error(data.message || 'Failed to update category');
            }
        } catch (error) {
            toast.error('Failed to update category');
        }
    };

    const handleDelete = (categoryId) => {
        setCategoryToDelete(categoryId); 
        const deleteModal = new window.bootstrap.Modal(document.getElementById('deleteCategoryModal'));
        deleteModal.show();
    };

    const handleCategoryDelete = async () => {
        try {
            const response = await fetch(`${backendURL}/api/categories/${categoryToDelete}`, {
                method: 'DELETE',
            });

            const data = await response.json();
            if (response.ok) {
                setCategories(categories.filter(category => category._id !== categoryToDelete)); // Remove from UI
                toast.success('Category deleted successfully');
                // Close modal
                const deleteModal = window.bootstrap.Modal.getInstance(document.getElementById('deleteCategoryModal'));
                deleteModal.hide();
            } else {
                toast.error(data.message || 'Failed to delete category');
            }
        } catch (error) {
            toast.error('Failed to delete category');
        }
    };

    return (
        <div className="text-center">
            <ToastContainer />

            <div className="p-4 pt-5">
                <div className="card shadow">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <h5 className="mb-0">Categories</h5>
                        <button
                            className="btn btn-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#addCategoryModal"
                        >
                            Add Category
                        </button>
                    </div>
                    <div className="card-body">
                        <table className="table">
                            <thead className="table-dark">
                                <tr>
                                    <th>#</th>
                                    <th>Category</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {categories.map((category, index) => (
                                    <tr key={category._id}>
                                        <td>{(page - 1) * limit + index + 1}</td>
                                        <td>{category.name}</td>
                                        <td>
                                            
                                            <button
                                                className="view-button"
                                                onClick={() => handleUpdate(category._id, category.name)}
                                            >
                                                Update
                                            </button>
                                            <button
                                                className="view-button"
                                                onClick={() => handleDelete(category._id)}
                                            >
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-start">
                                <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                                    <button
                                        className="page-link"
                                        onClick={handlePrevPage}
                                        disabled={page === 1}
                                    >
                                        Previous
                                    </button>
                                </li>
                                <span>
                                    Page {page} of {totalPages}
                                </span>
                                <li className={`page-item ${page === totalPages ? 'disabled' : ''}`}>
                                    <button
                                        className="page-link"
                                        onClick={handleNextPage}
                                        disabled={page === totalPages}
                                    >
                                        Next
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="addCategoryModal" tabIndex="-1" aria-labelledby="addCategoryModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addCategoryModalLabel">Add Category</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <input
                                type="text"
                                className="form-control"
                                value={newCategory}
                                onChange={(e) => setNewCategory(e.target.value)}
                                placeholder="Enter category name"
                            />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={handleAddCategory}>Add</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="updateCategoryModal" tabIndex="-1" aria-labelledby="updateCategoryModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="updateCategoryModalLabel">Update Category</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <input
                                type="text"
                                className="form-control"
                                value={updatedCategoryName}
                                onChange={(e) => setUpdatedCategoryName(e.target.value)}
                                placeholder="Enter new category name"
                            />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={handleCategoryUpdate}>Update</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="deleteCategoryModal" tabIndex="-1" aria-labelledby="deleteCategoryModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="deleteCategoryModalLabel">Delete Category</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            Are you sure you want to delete this category?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-danger" onClick={handleCategoryDelete}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Category;
