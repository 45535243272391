import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Signin.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  
  const navigate = useNavigate();

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const backendURL = process.env.REACT_APP_BackendURL; 
      const response = await fetch(`${backendURL}/api/auth/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      });
    
      const result = await response.json();
      setLoading(false);
      console.log('Login result:', result);
    
      if (response.ok) {
        localStorage.setItem('token', result.token);
        // alert('Login successful!');
        navigate('/dashboard');
      } else {
        alert(result.message || 'Login failed. Please try again.');
      }
    } catch (error) {
      console.error('Error during login:', error);
      alert('An error occurred. Please try again later.');
      setLoading(false);
    }
  };


  const handleSignUpClick = () => {
    navigate('/signup');
  };

  return (
<div className='login-page'>
    <div className="login-container" >
    <div className="login-form-container login-sign-in-container">
        <form className='login-form' onSubmit={handleSignIn}>
            <h1 className='login-heading'>Sign in</h1>
            <div className="login-social-container">
            </div>
            <div className='login-infield'>
                <input type="email" placeholder="Email"  value={email}
              onChange={(e) => setEmail(e.target.value)}/>
            </div>
            <div className='login-infield'>
                <input  type="password" placeholder="Password" value={password}
              onChange={(e) => setPassword(e.target.value)} />
            </div>
            {/* <a href="#" class="forgot">Forgot your password?</a> */}
            <button  className='login-button'>Sign In</button>
        </form>
    </div>
    <div className="login-overlay-container" >
        <div className="login-overlay">
            <div className="login-overlay-panel login-overlay-left">
                <h1>Welcome Back!</h1>
                <p>To keep connected with us please login with your personal info</p>
                <button>Sign In</button>
            </div>
            <div className="login-overlay-panel login-overlay-right">
                <h1>Medivo Admin!</h1>
                <p>Medivo: Your Trusted Partner in Medical Billing </p>
            </div>
        </div>
    </div>
</div>
</div>

  );
};

export default Login;
