import SideBar from './SideBar'
import TopNav from './TopNav';
import './Layout.css';


export default function DashboardLayout({children}) {
   
    return (
        <div>
          <div className="wrapper">
            <SideBar />
            <div className="main">
              <TopNav />
              <div >
                <main>{children}</main> {/* Render children properly */}
              </div>
            </div>
          </div>
        </div>
      );
}
