import React from 'react'

export default function TopNav() {
  return (
    <div>
      <nav class="navbar navbar-expand-lg p-4 navbar-light shadow" >
  <div class="container-fluidrr">
    <a class="navbar-brand" href="#">Dashboard</a>
    
  </div>
</nav>
    </div>
  )
}
